import React, { useEffect, useState } from "react";

import Section from "../Section";
import Breadcrumb from "../Breadcrumb";

import Spacing from "../Spacing";
import Post from "../Post";
import Sidebar from "../Sidebar";
import PostData from "./posts.json";

import { pageTitle } from "../../helpers/PageTitle";
import { useParams } from "react-router-dom";
const tags = [
  { tag: "Emergency", href: "/blog/blog-details" },
  { tag: "Pediatric", href: "/blog/blog-details" },
  { tag: "Cardiology", href: "/blog/blog-details" },
  { tag: "Psychiatry", href: "/blog/blog-details" },
  { tag: "Others", href: "/blog/blog-details" },
];
const relatedBlog = [
  {
    title:
      "The Importance of Mental Health: Understanding and Managing Anxiety Disorders",
    thumbUrl: "/images/blog/post_1.jpeg",
    date: "March 12",
    btnText: "Learn More",
    href: "/blog/blog-details",
  },
  {
    title: `A Parent's Guide to Childhood Vaccinations: What You Need to Know`,
    thumbUrl: "/images/blog/post_2.jpeg",
    date: "March 11",
    btnText: "Learn More",
    href: "/blog/blog-details",
  },
  {
    title: "Preventing Heart Disease: Tips for a Heart-Healthy Lifestyle",
    thumbUrl: "/images/blog/post_3.jpeg",
    date: "March 9",
    btnText: "Learn More",
    href: "/blog/blog-details",
  },
];

export default function BlogDetails() {
  // const [posts, setPosts] = useState([]);
  const [post, setPost] = useState({});
  const [postContent, setPostContent] = useState("");
  const [postTitle, setPostTitle] = useState("");
  const [postImage, setPostImage] = useState("");
  const slug = useParams();

  useEffect(() => {
    // Filter the array to find the post with the matching slug
    const selectedPost = PostData.find((p) => p.slug === slug.blogId);
    // console.log(selectedPost);
    setPost(selectedPost);
    setPostContent(selectedPost.content);
    setPostTitle(selectedPost.title);
    setPostImage(selectedPost.image);
  }, [slug]);

  pageTitle("Taywade Hospital", postTitle);
  return (
    <>
      <Section topMd={170} bottomMd={54} bottomLg={54}>
        <Breadcrumb title={postTitle} />
      </Section>

      <div className="container">
        <div className="cs_blog_details_info">
          <div className="cs_blog_details_info_left">
            <div className="cs_blog_details_tags"></div>
          </div>
        </div>
        <Spacing md="55" />
        <img
          src={postImage}
          alt="Blog Details"
          className="w-100 cs_radius_20"
        />
        <Spacing md="90" lg="50" />
        <div className="row ">
          <div className="col-lg-8 mx-auto">
            <div className="cs_blog_details">
              {post && (
                <div dangerouslySetInnerHTML={{ __html: postContent }} />
              )}
            </div>

            <Spacing md="85" />
          </div>
          {/* <div className="col-lg-4">
            <Sidebar />
          </div> */}
        </div>
        {/* <Spacing md="135" lg="100" />
        <h2 className="mb-0 cs_fs_40 cs_medium">Related Articles</h2>
        <Spacing md="57" /> */}
        {/* <div className="row cs_gap_y_40">
          {relatedBlog?.map((item, index) => (
            <div className="col-xl-4 col-md-6" key={index}>
              <Post {...item} />
            </div>
          ))}
        </div> */}
      </div>
    </>
  );
}

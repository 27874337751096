/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import BannerSectionStyle5 from "../Section/BannerSection/BannerSectionStyle5";
import ContactForm from "../ContactForm";
import Section from "../Section";
import ContactInfoSection from "../Section/ContactInfoSection";
import { pageTitle } from "../../helpers/PageTitle";

export default function BuiltBy() {
  pageTitle("Contact");
  return (
    <>
      <Section
        topMd={200}
        topLg={150}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <div style={{ textAlign: "center" }}>
          <h1>Built By</h1>
          <h2>Vipul D</h2>
          <h4>
            <a href="https://github.com/vipuldhomane/">GitHub Link</a>
          </h4>
        </div>
      </Section>
    </>
  );
}

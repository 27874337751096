import React, { useState } from "react";
import BannerSectionStyle9 from "../Section/BannerSection/BannerSectionStyle9";
import Section from "../Section";
import BlogSectionStyle2 from "../Section/BlogSection/BlogSectionStyle2";
import Breadcrumb from "../Breadcrumb";
import { pageTitle } from "../../helpers/PageTitle";
import PostData from "./posts.json";
import { useEffect } from "react";

const blogData = [
  {
    _id: {
      $oid: "6646fb4efcd77f2f56d02547",
    },
    userId: "660bbef8500c7a35d1520c22",

    title:
      "Taywade Multispeciality Hospital: Nagpur City's Best Option for Healthcare",
    image:
      "https://img.freepik.com/free-psd/interior-modern-emergency-room-with-empty-nurses-station-generative-ai_587448-2137.jpg?t=st=1715927807~exp=1715931407~hmac=9f947e1cb4b591236a7771122fedd4beb5f0a5d3c76e2747f0e8fdaf6d82e78d&w=900",
    category: "blog",
    slug: "taywade-multispeciality-hospital-nagpur-citys-best-option-for-healthcare",
    createdAt: {
      $date: "2024-05-17T06:38:06.614Z",
    },
    updatedAt: {
      $date: "2024-05-17T06:38:06.614Z",
    },
    __v: 0,
  },
  {
    _id: {
      $oid: "6646fb6afcd77f2f56d02550",
    },
    userId: "660bbef8500c7a35d1520c22",

    title: "Taywade Multispeciality Hospital Offers Complete Orthopedic Care",
    image:
      "https://img.freepik.com/free-psd/interior-modern-emergency-room-with-empty-nurses-station-generative-ai_587448-2137.jpg?t=st=1715927807~exp=1715931407~hmac=9f947e1cb4b591236a7771122fedd4beb5f0a5d3c76e2747f0e8fdaf6d82e78d&w=900",
    category: "uncategorized",
    slug: "taywade-multispeciality-hospital-offers-complete-orthopedic-care",
    createdAt: {
      $date: "2024-05-17T06:38:34.027Z",
    },
    updatedAt: {
      $date: "2024-05-17T06:38:34.027Z",
    },
    __v: 0,
  },
  {
    _id: {
      $oid: "6646fb82fcd77f2f56d02559",
    },
    userId: "660bbef8500c7a35d1520c22",

    title:
      "Improving Heart Health: Taywade Multispeciality Hospital's Skilled Cardiology Services",
    image:
      "https://img.freepik.com/free-psd/interior-modern-emergency-room-with-empty-nurses-station-generative-ai_587448-2137.jpg?t=st=1715927807~exp=1715931407~hmac=9f947e1cb4b591236a7771122fedd4beb5f0a5d3c76e2747f0e8fdaf6d82e78d&w=900",
    category: "uncategorized",
    slug: "improving-heart-health-taywade-multispeciality-hospitals-skilled-cardiology-services",
    createdAt: {
      $date: "2024-05-17T06:38:58.860Z",
    },
    updatedAt: {
      $date: "2024-05-17T06:38:58.860Z",
    },
    __v: 0,
  },
  {
    _id: {
      $oid: "6646fb99fcd77f2f56d02562",
    },
    userId: "660bbef8500c7a35d1520c22",

    title:
      "Taywade Multispeciality Hospital's Advanced Radiology Services: Accuracy and Deficiency",
    image:
      "https://img.freepik.com/free-psd/interior-modern-emergency-room-with-empty-nurses-station-generative-ai_587448-2137.jpg?t=st=1715927807~exp=1715931407~hmac=9f947e1cb4b591236a7771122fedd4beb5f0a5d3c76e2747f0e8fdaf6d82e78d&w=900",
    category: "uncategorized",
    slug: "taywade-multispeciality-hospitals-advanced-radiology-services-accuracy-and-deficiency",
    createdAt: {
      $date: "2024-05-17T06:39:21.123Z",
    },
    updatedAt: {
      $date: "2024-05-17T06:39:21.123Z",
    },
    __v: 0,
  },
  {
    title:
      "Managing Your Medical Travels: An Overview of Multispecialty Hospitals",
    image: "images/mainUpload/BlogHomeImg/multimini.jpg",
    // date: "March 12",
    btnText: "Learn More",
    slug: "/blog/blog1",
    socialShare: true,
  },
  {
    title: `Understand Orthopedic Care: Symptoms and Treatment`,
    image: "images/mainUpload/BlogHomeImg/orthomini.jpg",
    // date: "March 11",
    btnText: "Learn More",
    slug: "/blog/blog2",
    socialShare: true,
  },
  {
    title: "Nurturing Health from the Start: Understanding Pediatric Care",
    image: "images/mainUpload/BlogHomeImg/pediatricmini.jpg",
    // date: "March 9",
    btnText: "Learn More",
    slug: "/blog/blog3",
    socialShare: true,
  },
  {
    title: "Understanding Cardiology Care",
    image: "images/mainUpload/BlogHomeImg/cardiomini.jpg",
    // date: "March 8",
    btnText: "Learn More",
    slug: "/blog/blog4",
    socialShare: true,
  },
  {
    title: `Empowering Women's Health: Navigating Gynecology Care`,
    image: "/images/mainUpload/BlogHomeImg/gynomini.jpg",
    // date: "March 5",
    btnText: "Learn More",
    slug: "/blog/blog5",
    socialShare: true,
  },
  {
    title: "Neurology Care: Understanding Symptoms and Treatment",
    image: "/images/mainUpload/BlogHomeImg/nueromini.jpg",
    // date: "March 2",
    btnText: "Learn More",
    slug: "/blog/blog6",
    socialShare: true,
  },
];

export default function Blog() {
  const [blogs, setBlogs] = useState([]);
  // useEffect(() => {
  //   PostData.map((blog) => console.log(blog));
  // }, []);

  useEffect(() => {
    // Update the state with PostData
    setBlogs(PostData);
  }, []);

  pageTitle("Blog");
  return (
    <>
      <Section topMd={170} bottomMd={96} bottomLg={70}>
        <Breadcrumb title="Read Latest Blogs" />
      </Section>
      <Section bottomMd={200} bottomLg={150} bottomXl={110}>
        <BlogSectionStyle2 data={blogs} />
      </Section>
    </>
  );
}

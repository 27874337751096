export const relatedBlogData = [
  {
    title: `Understand Orthopedic Care: Symptoms and Treatment`,
    thumbUrl: "/images/mainUpload/BlogHomeImg/orthomini.jpg",
    date: "March 11",
    btnText: "Learn More",
    href: "/blog/blog2",
  },
  {
    title: "Understanding Cardiology Care",
    thumbUrl: "/images/mainUpload/BlogHomeImg/cardiomini.jpg",
    date: "March 8",
    btnText: "Learn More",
    href: "/blog/blog4",
  },
  {
    title: `Empowering Women's Health: Navigating Gynecology Care`,
    thumbUrl: "/images/mainUpload/BlogHomeImg/gynomini.jpg",
    date: "March 5",
    btnText: "Learn More",
    href: "/blog/blog5",
  },
];

import React from "react";

import Section from "../Section";
import Breadcrumb from "../Breadcrumb";

import Spacing from "../Spacing";
import Post from "../Post";
import Sidebar from "../Sidebar";

import { pageTitle } from "../../helpers/PageTitle";
import { relatedBlogData } from "./relatedBlogData";
const tags = [
  { tag: "Emergency", href: "/blog/blog-details" },
  { tag: "Pediatric", href: "/blog/blog-details" },
  { tag: "Cardiology", href: "/blog/blog-details" },
  { tag: "Psychiatry", href: "/blog/blog-details" },
  { tag: "Others", href: "/blog/blog-details" },
];

const relatedBlog = relatedBlogData;
export default function Blog4() {
  pageTitle("Blog Details");
  return (
    <>
      <Section topMd={170} bottomMd={54} bottomLg={54}>
        <Breadcrumb title="Understanding Cardiology Care" />
      </Section>
      <div className="container">
        <div className="cs_blog_details_info">
          <div className="cs_blog_details_info_left">
            <div className="cs_blog_details_tags">
              {/* {tags.map((item, index) => (
                <Link key={index} to={item.href}>
                  {item.tag}
                </Link>
              ))} */}
            </div>
            <div className="cs_blog_details_date">
              March 12, 2023 | Debri Bianca
            </div>
          </div>
          {/* <div className="cs_social_links_wrap">
            <h2>Share:</h2>
            <div className="cs_social_links">
              <Link to="/">
                <Icon icon="fa-brands:facebook-f" />
              </Link>
              <Link to="/">
                <Icon icon="fa-brands:linkedin-in" />
              </Link>
              <Link to="/">
                <Icon icon="fa-brands:twitter" />
              </Link>
            </div>
          </div> */}
        </div>
        <Spacing md="55" />
        <img
          src="/images/mainUpload/BlogImg/cardio.jpg"
          alt="Blog Details"
          className="w-100 cs_radius_20"
        />
        <Spacing md="90" lg="50" />
        <div className="row">
          <div className="col-lg-8">
            <div className="cs_blog_details">
              <h2>Understanding Cardiology Care</h2>
              <p>
                At the core of our cardiovascular system, the heart is a
                wonderful organ essential to our general health. When it comes
                to heart issues, it's critical to recognize the signs, get
                treatment as soon as possible, and take preventative action. In
                this blog, we delve into cardiology care, looking at typical
                symptoms, underlying reasons, and the route to heart health.
              </p>

              <h3>
                <b>Identification of Cardiology Symptoms</b>
              </h3>
              <ol>
                <li>
                  <b>Chest Pain: </b>
                  <br />
                  Chest pain or discomfort is one of the most typical signs of
                  heart-related problems. It may feel as though there is
                  pressure, tightness, or squeezing in the chest, and its
                  intensity can vary. Radiation from chest pain might also
                  affect the jaw, neck, back, or arms.
                </li>
                <li>
                  <b>Breathlessness:</b> <br />
                  Breathing difficulties, particularly when exercising or lying
                  down, may indicate a cardiac condition. Breathlessness might
                  be sudden or worsen over time, pointing to heart failure or
                  coronary artery disease as possible causes.
                </li>
                <li>
                  <b>Palpitations:</b>
                  <br />
                  Palpitations, also known as irregular heartbeats, can cause
                  fluttering, a fast heartbeat, or skipped beats. Palpitations
                  can indicate underlying arrhythmias or other cardiac
                  abnormalities that need to be evaluated, even if they can also
                  be innocuous.
                </li>
                <li>
                  <b>Exhaustion:</b>
                  <br />
                  If weakness or exhaustion persists over time and interferes
                  with day-to-day activities, it may indicate an underlying
                  cardiac issue. Feelings of fatigue and sluggishness can arise
                  from insufficient oxygenation of tissues caused by decreased
                  blood flow to the body.
                </li>
                <li>
                  <b> Swelling:</b> <br />
                  Swelling, or swelling, in the belly, feet, ankles, or legs may
                  indicate heart failure or other cardiac problems. When the
                  heart cannot circulate blood efficiently, fluid accumulates in
                  the body's tissues and causes fluid retention.
                </li>
              </ol>

              <h2>Understanding Cardiology Conditions and Treatments</h2>

              <ul>
                <li>
                  <h3>Coronary Artery Disease (CAD): </h3>
                  CAD is the result of plaque accumulation narrowing or
                  obstructing the coronary arteries, which reduces heart blood
                  flow. Treatment options include medication, angioplasty,
                  stenting, coronary artery bypass surgery, and lifestyle
                  changes.
                </li>
                <li>
                  <h3> Heart Failure:</h3>
                  The accumulation of fluid in the lungs and other tissues is
                  caused by the heart's inability to pump blood effectively. The
                  goals of treatment are to control symptoms, strengthen the
                  heart, and deal with underlying issues such coronary artery
                  disease or hypertension.
                </li>
                <li>
                  <h3>Arrhythmias:</h3>
                  Abnormal heart rhythms, or arrhythmias, can cause
                  palpitations, lightheadedness, fainting, or soreness in the
                  chest. Depending on the kind and severity of the arrhythmia,
                  treatment options include medication, cardiac ablation,
                  implanted devices (pacemakers or defibrillators), or surgery.
                </li>
                <li>
                  <h3>Heart Valve Dysfunction or Abnormalities: </h3>
                  Heart valve dysfunction or abnormalities impede the heart's
                  ability to pump blood. Medication, surgery for valve
                  replacement or repair, or minimally invasive techniques like
                  transcatheter valve treatments are possible forms of
                  treatment.
                </li>
                <li>
                  <h3> High blood pressure, or hypertension :</h3>
                  is a major risk factor for stroke and cardiovascular disease.
                  To lower the risk of problems and control blood pressure,
                  treatment usually consists of medication, lifestyle changes
                  (diet, exercise, stress management), and routine monitoring.
                </li>
              </ul>
              <h2>Conclusion</h2>
              <p>
                Protecting heart health and averting cardiovascular disease
                require cardiology treatment. People may preserve their hearts
                and live longer, healthier lives by being proactive about their
                health, identifying symptoms, and seeking timely care. Remind
                yourself that the knowledge and commitment of cardiology
                specialists means your heart is in good hands. Make heart health
                a priority now to ensure a better tomorrow.
              </p>
            </div>
            <Spacing md="85" />
            {/* <AuthorWidget
              imgUrl="/images/blog/author.png"
              name="Author Bio"
              description="John Smith is a freelance writer and content strategist with a passion for helping businesses tell their stories. With over 10 years of experience in the industry, John has worked with a wide range of clients, from startups to Fortune 500 companies. He holds a Bachelor's degree in English from the University of California, Los Angeles (UCLA), and is an avid reader and traveler in his free time. Follow him on Twitter @johnsmithwriter for the latest updates on his work."
            /> */}
            {/* <Spacing md="110" />
            <CommentsWidget title="Comments" />
            <Spacing md="92" />
            <ReplyWidget title="Leave a Reply" /> */}
          </div>
          <div className="col-lg-4">
            <Sidebar />
          </div>
        </div>
        <Spacing md="135" lg="100" />
        <h2 className="mb-0 cs_fs_40 cs_medium">Related Articles</h2>
        <Spacing md="57" />
        <div className="row cs_gap_y_40">
          {relatedBlog?.map((item, index) => (
            <div className="col-xl-4 col-md-6" key={index}>
              <Post {...item} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
